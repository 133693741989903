import { FS_EVENT, CAMPAIGN_TYPES } from './commonConst'

const EVENT_LEVEL = 'event_level'
const FARM_LEVEL = 'farm_level'

export const EVIDENCE_LEVEL = Object.freeze({
  EVENT_LEVEL,
  FARM_LEVEL
})

/**
 * @property descriptionKey: translation key used for evidence description lookup
 * @property evidenceType: the BE enum value for the evidence type
 * @property fsEvent: field story event
 * @property instructions: translation keys for rendering upload instructions in the UploadFilesDrawer
 * @property isRequestedForNERP: boolean depicting whether or not the evidence type is required for NERP
 * @property value: specified event category
 * @property title: if title is different from evidenceType we fall back on using the title otherwise render the evidence type as title
 */
export const EVIDENCE_TYPE = Object.freeze({
  BLENDER_SCALE_TICKETS: Object.freeze({
    evidenceType: 'Blender Scale Tickets',
    fsEvent: FS_EVENT.FERTILIZER,
    requiresGeoTimestamp: false,
    value: 'BLENDER_SCALE_TICKETS',
    title: 'Fertilizer Rate'
  }),
  CROP_TYPE: Object.freeze({
    evidenceType: 'Crop Type',
    fsEvent: FS_EVENT.PLANTING,
    isRequestedForNERP: true,
    isRequestedForSNO: true,
    requiresGeoTimestamp: true,
    value: 'CROP_TYPE',
    instructions: {
      descriptionKey: 'any_of_following_accepted',
      itemsKeys: [
        'crop_type_scouting_report',
        'crop_type_insurance_records',
        'crop_type_geo_timestamp'
      ],
      infoPopover: {
        labelKey: 'what_is_geo_time_label',
        contentKey: 'what_is_geo_time_content'
      }
    }
  }),
  CONTRACT: Object.freeze({
    evidenceType: 'Contract',
    infoHasIntercomButton: true,
    fsEvent: null,
    value: 'CONTRACT'
  }),
  FARM_APPLICATION_APPLIED_DATA: Object.freeze({
    descriptionKey: '',
    evidenceType: 'Farm Application Applied Data',
    fsEvent: [
      FS_EVENT.CHEMICAL,
      FS_EVENT.FERTILIZER,
      FS_EVENT.HARVEST,
      FS_EVENT.PLANTING,
      FS_EVENT.TILLAGE
    ],
    isRequestedForNERP: false,
    requiresGeoTimestamp: false,
    value: 'FARM_APPLICATION_APPLIED_DATA'
  }),
  FERTILIZER_BAND_WIDTH: Object.freeze({
    evidenceType: 'Fertilizer Band Width',
    fsEvent: FS_EVENT.FERTILIZER,
    isRequestedForNERP: true,
    requiresGeoTimestamp: false,
    value: 'FERTILIZER_BAND_WIDTH',
    instructions: {
      descriptionKey: 'fertilizer_band_instruction_description',
      itemsKeys: []
    }
  }),
  FERTILIZER_RATE: Object.freeze({
    evidenceType: 'Fertilizer Rate',
    fsEvent: FS_EVENT.FERTILIZER,
    isRequestedForNERP: true,
    isRequestedForSNO: true,
    requiresGeoTimestamp: false,
    value: 'FERTILIZER_RATE',
    instructions: {
      descriptionKey: 'we_will_accept',
      itemsKeys: [
        'fertilizer_receipts_photos',
        'fertilizer_receipts_as_applied'
      ]
    }
  }),
  '4_R_PLAN': Object.freeze({
    evidenceType: '4R Plan',
    FS_EVENT: FS_EVENT.PLAN_DRAWER,
    isRequestedForNERP: true,
    requiresGeoTimestamp: false,
    value: '4_R_PLAN'
  }),
  FSA_REPORT: Object.freeze({
    evidenceType: 'FSA Report',
    fsEvent: FS_EVENT.HARVEST,
    requiresGeoTimestamp: false,
    value: 'FSA_REPORT'
  }),
  INSURANCE_DOCUMENTATION_OF_LOSS: Object.freeze({
    evidenceType: 'Insurance Documentation of Loss',
    fsEvent: FS_EVENT.HARVEST,
    requiresGeoTimestamp: false,
    value: 'INSURANCE_DOCUMENTATION_OF_LOSS'
  }),
  INSURANCE_PRODUCTION_WORKSHEET: Object.freeze({
    evidenceType: 'Insurance Production Worksheet',
    fsEvent: '',
    requiresGeoTimestamp: false,
    value: 'INSURANCE_PRODUCTION_WORKSHEET'
  }),
  IRRIGATION_FLOW_METER: Object.freeze({
    evidenceType: 'Irrigation Flow Meter',
    fsEvent: FS_EVENT.IRRIGATION,
    requiresGeoTimestamp: false,
    value: 'IRRIGATION_FLOW_METER',
    title: 'Irrigation Flow Meter'
  }),
  IRRIGATION_APPLICATION: Object.freeze({
    evidenceType: 'Irrigation Application',
    fsEvent: FS_EVENT.IRRIGATION,
    isRequestedForNERP: true,
    value: 'IRRIGATION_APPLICATION',
    requiresGeoTimestamp: false,
    instructions: {
      descriptionKey: 'we_will_accept',
      itemsKeys: [
        'irrigation_app_employee_scouting',
        'irrigation_app_water_use',
        'irrigation_app_district'
      ]
    }
  }),
  MANURE_APPLICATION: Object.freeze({
    evidenceType: 'Manure Application',
    fsEvent: FS_EVENT.FERTILIZER,
    instructions: {
      descriptionKey: 'manure_sample_instructions',
      itemsKeys: []
    },
    isRequestedForNERP: false,
    isRequestedForSNO: true,
    value: 'MANURE_APPLICATION',
    title: 'Manure Application',
    requiresGeoTimestamp: false
  }),
  N_BALANCE_REPORT: Object.freeze({
    evidenceType: 'N Balance Calculation',
    fsEvent: FS_EVENT.SAMPLING,
    isRequestedForNERP: true,
    isRequestedForSNO: true,
    value: 'N_BALANCE_REPORT',
    requiresGeoTimestamp: false,
    title: 'N Balance Report',
    instructions: {
      descriptionKey: 'any_of_following_accepted',
      itemsKeys: [
        'soil_sample_annual_test_report',
        'soil_sample_annual_n_balance'
      ]
    }
  }),
  OTHER_RECORDS: Object.freeze({
    evidenceType: null,
    fsEvent: FS_EVENT,
    isRequestedForNERP: false,
    value: 'OTHER_RECORDS',
    requiresGeoTimestamp: false,
    title: 'other_records'
  }),
  SEEDING_IMPLEMENT: Object.freeze({
    evidenceType: 'Seeding Implement',
    fsEvent: FS_EVENT.PLANTING,
    isRequestedForNERP: true,
    value: 'SEEDING_IMPLEMENT',
    requiresGeoTimestamp: false,
    instructions: {
      descriptionKey: 'seeding_implement_description',
      itemsKeys: []
    }
  }),
  SOC_SAMPLE_RESULTS: Object.freeze({
    fsEvent: FS_EVENT.SAMPLING,
    evidenceType: 'SOC Sample Results',
    value: 'SOC_SAMPLE_RESULTS',
    requiresGeoTimestamp: false,
    instructions: {
      descriptionKey: 'soc_sample_result_instructions',
      itemsKeys: []
    }
  }),
  SOIL_SAMPLE_RESULTS: Object.freeze({
    evidenceType: 'Soil Sample Results',
    fsEvent: FS_EVENT.SAMPLING,
    isRequestedForNERP: true,
    isRequestedForSNO: true,
    value: 'SOIL_SAMPLE_RESULTS',
    requiresGeoTimestamp: false,
    instructions: {
      descriptionKey: 'soil_sample_results_instructions',
      itemsKeys: []
    }
  }),
  SOIL_SAMPLE_PLAN: Object.freeze({
    evidenceType: 'Soil Sample Plan',
    descriptionKey: 'SOIL_SAMPLE_PLAN_DESCRIPTION',
    isRequestedForNERP: true,
    value: 'SOIL_SAMPLE_PLAN',
    title: 'Soil Sample or N-Balance',
    instructions: {
      descriptionKey: 'we_will_accept',
      itemsKeys: ['soil_sample_annual_soil', 'soil_sample_annual_n_balance']
    }
  }),
  SOIL_TEMPERATURE: Object.freeze({
    evidenceType: 'Soil Temperature',
    fsEvent: FS_EVENT.FERTILIZER,
    isRequestedForNERP: true,
    value: 'SOIL_TEMPERATURE',
    requiresGeoTimestamp: false,
    instructions: {
      descriptionKey: 'we_will_accept',
      itemsKeys: ['soil_temp_pictures', 'soil_temp_weather_station']
    }
  }),
  SPRAY_LOGS: Object.freeze({
    descriptionKey: '',
    evidenceType: 'Spray Logs',
    fsEvent: Object.freeze([
      FS_EVENT.CHEMICAL,
      FS_EVENT.FERTILIZER,
      FS_EVENT.HARVEST,
      FS_EVENT.PLANTING,
      FS_EVENT.TILLAGE
    ]),
    isRequestedForNERP: false,
    value: 'SPRAY_LOGS',
    requiresGeoTimestamp: false
  }),
  TILLAGE_IMPLEMENT_PHOTOS: Object.freeze({
    evidenceType: 'Tillage Implement Photos',
    fsEvent: FS_EVENT.TILLAGE,
    value: 'TILLAGE_IMPLEMENT_PHOTOS',
    requiresGeoTimestamp: false,
    title: 'Tillage Equipment'
  }),
  TILLAGE_REDUCTION: Object.freeze({
    evidenceType: 'Tillage Reduction',
    fsEvent: FS_EVENT.TILLAGE,
    isRequestedForNERP: false,
    isRequestedForSNO: true,
    requiresGeoTimestamp: false,
    instructions: {
      descriptionKey: 'tillage_reduction_instructions'
    },
    value: 'TILLAGE_REDUCTION'
  }),
  TISSUE_SAMPLES: Object.freeze({
    evidenceType: 'Tissue Samples',
    requiresGeoTimestamp: false,
    fsEvent: FS_EVENT.SAMPLING,
    value: 'TISSUE_SAMPLES'
  }),
  WATER_SAMPLES: Object.freeze({
    evidenceType: 'Water Samples',
    fsEvent: FS_EVENT.SAMPLING,
    requiresGeoTimestamp: false,
    value: 'WATER_SAMPLES'
  }),
  WEIGH_TICKET: Object.freeze({
    evidenceType: 'Weigh Ticket',
    fsEvent: FS_EVENT.HARVEST,
    isRequestedForNERP: true,
    isRequestedForSNO: true,
    requiresGeoTimestamp: false,
    value: 'WEIGH_TICKET',
    title: 'Yield',
    instructions: {
      descriptionKey: 'any_of_following_accepted',
      itemsKeys: [
        'weigh_ticket_monitor_data',
        'weigh_ticket_loadcell_data',
        'weigh_ticket_sales_or_shipping',
        'weigh_ticket_yield_estimate'
      ]
    }
  }),
  YIELD: Object.freeze({
    evidenceType: 'Yield',
    fsEvent: FS_EVENT.HARVEST,
    isRequestedForNERP: true,
    isRequestedForSNO: true,
    value: 'YIELD',
    requiresGeoTimestamp: false,
    instructions: {
      descriptionKey: 'any_of_following_accepted',
      itemsKeys: [
        'weigh_ticket_monitor_data',
        'weigh_ticket_loadcell_data',
        'weigh_ticket_sales_or_shipping',
        'weigh_ticket_yield_estimate'
      ]
    }
  })
})

export const OVERRIDE_EVIDENCE_TYPE = Object.freeze({
  [FS_EVENT.FERTILIZER]: {
    [CAMPAIGN_TYPES.NERP]: {
      'Fertilizer Band Width': {
        isRequired: true
      }
    },
    [CAMPAIGN_TYPES.SNO]: {
      'Manure Application': {
        isRequired: true
      }
    }
  }
})

// Field story events that do not have a requested or non-requested evidence type should
// have a value of an empty array - e.g. [FS_EVENT.CHEMICAL]: [],
export const EVIDENCE_MAPPING = Object.freeze({
  [FS_EVENT.SAMPLING]: [
    EVIDENCE_TYPE.SOIL_SAMPLE_RESULTS,
    EVIDENCE_TYPE.SEEDING_IMPLEMENT,
    EVIDENCE_TYPE.SOC_SAMPLE_RESULTS
  ],
  [FS_EVENT.CHEMICAL]: [],
  [FS_EVENT.PLANTING]: [
    EVIDENCE_TYPE.CROP_TYPE,
    EVIDENCE_TYPE.SEEDING_IMPLEMENT
  ],
  [FS_EVENT.HARVEST]: [EVIDENCE_TYPE.YIELD],
  [FS_EVENT.IRRIGATION]: [EVIDENCE_TYPE.IRRIGATION_APPLICATION],
  [FS_EVENT.FERTILIZER]: [
    EVIDENCE_TYPE.FERTILIZER_BAND_WIDTH,
    EVIDENCE_TYPE.FERTILIZER_RATE,
    EVIDENCE_TYPE.MANURE_APPLICATION,
    EVIDENCE_TYPE.SOIL_TEMPERATURE
  ],
  [FS_EVENT.TILLAGE]: [EVIDENCE_TYPE.TILLAGE_REDUCTION],
  [FS_EVENT.PLAN_DRAWER]: [
    EVIDENCE_TYPE.SOIL_SAMPLE_PLAN,
    EVIDENCE_TYPE['4_R_PLAN']
  ]
})
