import { DRY, WET, LIQUID } from './commonConst'

const CHEMICAL_APPLICATION = 'ChemicalApplication'
const CROP_PROTECTION = 'CropProtection'
const FERTILIZER_APPLICATION = 'FertilizerApplication'
const FERTILIZATION = 'Fertilization' // event type on front end uses fertilization
const HAIL_LOG = 'HailLog'
const HARVEST = 'harvest'
const HARVESTCOMPLETION = 'Harvest Completion'
const HARVEST_COMPLETION = 'HarvestCompletion'
const HARVEST_MIN = 'HarvestMin'
const IRRIGATION = 'Irrigation'
const IRRIGATION_INDIVIDUAL = 'IrrigationIndividual'
const IRRIGATION_SEASON_TOTAL = 'IrrigationSeasonTotal'
const PLANTING_COMPLETION = 'PlantingCompletion'
const PLANTING_COMPLETE = 'Planting Completion'
const SOIL_TEST = 'SoilTest'
const TILLAGE = 'Tillage'
const BEST_PRACTICES = 'BestPractices'
const GENERAL_PRACTICES = 'GeneralPractices'
const FIELD_CHARACTERISTICS = 'FieldCharacteristics'
const PLAN = 'Plan'
const CROPPING_SEASON = 'CroppingSeason'
const COMPLETED = 'completed'
const DELETE = 'delete'
const END_DATE = 'EndDate'
const START_DATE = 'StartDate'
const COVER_CROP = 'CoverCrop'
const ORG_LEVEL_EUDR_DATA = 'orgLevelEudrData'

export const EVENTS = Object.freeze({
  BEST_PRACTICES,
  CHEMICAL_APPLICATION,
  COMPLETED,
  COVER_CROP,
  CROPPING_SEASON,
  CROP_PROTECTION,
  DELETE,
  END_DATE,
  FERTILIZATION,
  FERTILIZER_APPLICATION,
  FIELD_CHARACTERISTICS,
  GENERAL_PRACTICES,
  HAIL_LOG,
  HARVEST,
  HARVEST_COMPLETION,
  HARVESTCOMPLETION,
  // use minimal implementation of Harvest Drawer for CARNMPP campaigns
  HARVEST_MIN,
  IRRIGATION,
  IRRIGATION_INDIVIDUAL,
  IRRIGATION_SEASON_TOTAL,
  ORG_LEVEL_EUDR_DATA,
  PLAN,
  PLANTING_COMPLETE,
  PLANTING_COMPLETION,
  SOIL_TEST,
  TILLAGE,
  START_DATE
})

export const NO_EVENT_ACKNOWLEDGEMENT = Object.freeze({
  [EVENTS.COVER_CROP]: 'noCoverCropAcknowledged',
  [EVENTS.BEST_PRACTICES]: 'noBestPracticesAcknowledged',
  [EVENTS.CHEMICAL_APPLICATION]: 'noChemicalApplicationsAcknowledged',
  [EVENTS.FERTILIZER_APPLICATION]: 'noFertilizerApplicationsAcknowledged',
  [EVENTS.IRRIGATION]: 'noIrrigationAcknowledged',
  [EVENTS.SOIL_TEST]: 'noSoilTestsAcknowledged',
  [EVENTS.TILLAGE]: 'noTillageAcknowledged'
})

// Fertilizer Kind
const MANURE = 'Manure'
const SYNTHETIC = 'Synthetic'

/**
 * A dictionary of Fertilizer Kind
 */
export const FERTILIZER_KIND = Object.freeze({
  MANURE,
  SYNTHETIC
})

// Fertilizer Type
const ALL = 'All'
const PRIMARY = 'Primary'
const SECONDARY = 'Secondary'
const MICRONUTRIENT = 'Micronutrient'
const ORGANIC = 'Organic'
const LIQUID_MANURE = 'LiquidManure'
const DRY_MANURE = 'DryManure'

/**
 * A dictionary of Fertilizer Types
 */
export const FERTILIZER_TYPES = Object.freeze({
  ALL,
  DRY,
  DRY_MANURE,
  LIQUID,
  LIQUID_MANURE,
  MICRONUTRIENT,
  ORGANIC,
  PRIMARY,
  SECONDARY
})

// Template Kinds
const BLEND = 'blend'
const APPLICATOR = 'applicator'
const TEMPLATE = 'template'
const CHEMICAL_MIX = 'chemical'
const SYSTEM = 'system'

/**
 * A dictionary Template Kinds
 */
export const TEMPLATE_KINDS = Object.freeze({
  APPLICATOR,
  BLEND,
  CHEMICAL_MIX,
  SYSTEM,
  TEMPLATE
})

// Applicator Kinds
const CHEMICAL = 'Chemical'
const FERTILIZER = 'Fertilizer'

/**
 * A dictionary Applicator Kinds
 */
export const APPLICATOR_KINDS = Object.freeze({
  CHEMICAL,
  FERTILIZER
})

const CREATE_ONE_TIME = 'createOneTime'
const CREATE_SAVED = 'createSaved'
const EDIT_SAVED = 'editSaved'
const EDIT_ONE_TIME = 'editOneTime'

export const SYSTEM_DRAWER_TYPES = Object.freeze({
  CREATE_ONE_TIME,
  CREATE_SAVED,
  EDIT_ONE_TIME,
  EDIT_SAVED
})

// Chem Event Options
const oneTimeMix = 'oneTimeMix'
const savedMix = 'savedMix'
export const pleaseReview = 'Please Review'

export const CHEM_OPTIONS = Object.freeze({
  oneTimeMix,
  savedMix
})

// System Save Option
const oneTimeSystem = 'oneTimeSystem'
const savedSystem = 'savedSystem'

export const SYSTEM_SAVE_OPTIONS = Object.freeze({
  oneTimeSystem,
  savedSystem
})
// yield options

export const YIELD_OPTIONS = Object.freeze({
  DRY,
  WET
})

// Carriers
const WATER = 'Water'
const LIQUID_FERT = 'LiquidFertilizer'
const DRY_FERT = 'DryFertilizer'
const OTHER = 'Other'

// ADDITIVES
// FERTILIZER IS DECLARED ABOVE
const SPREADER = 'Spreader'
const STICKER = 'Sticker'
const ADJUVANT = 'Adjuvant'

export const CARRIERS = [WATER, LIQUID_FERT, DRY_FERT, OTHER]
export const ADDITIVES = [FERTILIZER, SPREADER, STICKER, ADJUVANT]

// FERTILIZER APPLICATION TYPES
const BANDED = 'Banded'
const BROADCAST = 'Broadcast'
const COULTER = 'Coulter'
const FERTIGATED = 'Fertigated'
const FOLIAR = 'Foliar'
const INJECTED = 'Injected'
const KNIFE = 'Knife'

export const FERTILIZER_APPLICATION_TYPES = Object.freeze({
  BANDED,
  BROADCAST,
  COULTER,
  FERTIGATED,
  FOLIAR,
  INJECTED,
  KNIFE,
  TILLAGE
})

export const SEEDING_METHODS = Object.freeze({
  AERIAL: 'Aerial',
  BROADCAST
})

export const APPLICATION_TYPES_WITH_DEPTH_MAP = Object.freeze({
  Banded: BANDED,
  Coulter: COULTER,
  Injected: INJECTED,
  Knife: KNIFE,
  Tillage: TILLAGE
})

// OPENER TYPES
const DISK = 'Disk'
const HOE = 'Hoe'
const SPOON = 'Spoon'
const SWEEP = 'Sweep'

export const OPENER_TYPES = Object.freeze({
  DISK,
  HOE,
  KNIFE,
  SPOON,
  SWEEP
})

/**
 *  A dictionary of the different types of flood types
 */
const PINPOINT = 'Pinpoint Flooding'
const INTERMITTENT = 'Intermittent Flooding'
const CONTINUOUS = 'Continuous Flooding'
const FLOOD = 'Flood'
const SURFACE_IRRIGATION = 'Surface Irrigation'
const FURROW = 'Furrow'
const LEVEL_BASIN = 'Level Basin'

export const FLOOD_TYPES_VALUES = Object.freeze({
  CONTINUOUS,
  INTERMITTENT,
  PINPOINT
})

export const IRRIGATION_FLOOD_TYPES = Object.freeze([
  FLOOD,
  SURFACE_IRRIGATION,
  FURROW,
  LEVEL_BASIN
])

const SURFACE = 'Surface'
const SUBSURFACE = 'Subsurface'
const DEEP = 'Deep'
const CUSTOM = 'Custom'

export const SAMPLE_DEPTH_TYPES = Object.freeze({
  CUSTOM,
  DEEP,
  SUBSURFACE,
  SURFACE
})
const SUCCESS = 'Uploaded successfully'
const LOADING = 'loading'
const ERROR_GEO_TIMESTAMP = 'error_geo_timestamp'
const ERROR_FILE_SIZE = 'error_file_size'
const ERROR_NOT_SUPPORTED_EXTENSION = 'error_not_supported_extension'
const ERROR_CANCELLED = 'error_cancelled'
const ERROR_IMAGE_FORMAT = 'error_image_format'
const UPLOADING = 'uploading'
const UPLOAD_SUCCESS = 'upload_success'
const PENDING = 'pending'
const FAILED_VIRUS_SCAN = 'error_failed_virus_scan'
const UPLOAD_COMPLETE = 'upload_complete'

export const ERROR = 'error'

export const UPLOAD_STATUS = Object.freeze({
  ERROR,
  ERROR_CANCELLED,
  ERROR_FILE_SIZE,
  ERROR_GEO_TIMESTAMP,
  ERROR_IMAGE_FORMAT,
  ERROR_NOT_SUPPORTED_EXTENSION,
  FAILED_VIRUS_SCAN,
  LOADING,
  PENDING,
  SUCCESS,
  UPLOADING,
  UPLOAD_COMPLETE,
  UPLOAD_SUCCESS
})

const UPLOAD_IS_COMPLETE = 'Upload Complete'
const UPLOAD_FAILED_VIRUS_SCAN = 'Failed Virus Scan'
const UPLOAD_PENDING = 'Pending'

export const API_UPLOAD_STATUS = Object.freeze({
  UPLOAD_FAILED_VIRUS_SCAN,
  UPLOAD_IS_COMPLETE,
  UPLOAD_PENDING
})
