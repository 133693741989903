import { arrayOf, bool, number, oneOf, shape, string, object } from 'prop-types'
import { COUNTRIES, MEMBERSHIP_STATUS } from 'const'

export const communityDashboardAccessType = shape({
  canViewStakeholderDashboard: bool,
  canViewEmployeeDashboard: bool
})

export const commitmentRequirementsType = shape({
  crop: number,
  specificCrop: number,
  years: arrayOf(number)
})

export const camapaignType = shape({
  uuid: string,
  name: string,
  shortDescription: string,
  year: arrayOf(number),
  enrollmentStartDate: string,
  enrollmentEndDate: string,
  campaignStartDate: string,
  campaignEndDate: string,
  planDeadline: string,
  commitmentRequirements: arrayOf(commitmentRequirementsType),
  country: oneOf(Object.values(COUNTRIES)),
  crops: arrayOf(string),
  isFtm: bool,
  isCfa: bool,
  isCarnmpp: bool,
  isNerp: bool,
  protocolName: string,
  termsAndConditions: object
})

export const membershipType = shape({
  uuid: string,
  status: oneOf(Object.values(MEMBERSHIP_STATUS)),
  campaign: camapaignType,
  invitationDate: string,
  enrollmentDate: string,
  isSelfEnrolled: bool,
  selfEnrollmentCode: string,
  isDirectContactable: bool,
  isFiscalIncentiveResolved: bool,
  subscriptionIncentiveGranted: bool,
  invitationViewed: bool,
  profile: string,
  acceptedTermsAndConditions: number,
  cropping_episodes: arrayOf(number)
})

export const sustainabilityCroppingSeasonType = shape({
  seasonName: string,
  rotationSeasonId: string,
  croppingEpisodeId: number,
  isFallow: bool,
  isCoverCrop: bool,
  noFertilizerApplicationsAcknowledged: bool,
  isTargetSeason: bool,
  isRotationSeason: bool,
  isBaselineRequired: bool,
  canEdit: bool,
  canDelete: bool,
  events: arrayOf(
    shape({
      eventType: string,
      date: string,
      id: number,
      status: string
    })
  )
})

export const incentiveProdcuctType = shape({
  id: number,
  name: string
})

export const incentiveType = shape({
  incentive: string,
  products: arrayOf(incentiveProdcuctType)
})

export const fieldCandidates = shape({
  validFields: arrayOf(
    shape({
      uuid: string,
      name: string,
      areaValue: number,
      areaUnits: string,
      farm: shape({
        uuid: string,
        name: string
      })
    })
  ),
  invalidFields: arrayOf(
    shape({
      uuid: string,
      name: string,
      areaValue: number,
      areaUnits: string,
      farm: shape({
        uuid: string,
        name: string
      })
    })
  ),
  duplicateFields: arrayOf(
    shape({
      uuid: string,
      name: string,
      areaValue: number,
      areaUnits: string,
      farm: shape({
        uuid: string,
        name: string
      })
    })
  )
})
