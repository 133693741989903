import {
  any,
  arrayOf,
  object,
  objectOf,
  bool,
  func,
  instanceOf,
  oneOf,
  oneOfType,
  number,
  shape,
  string
} from 'prop-types'
import { EVIDENCE_TYPE, ENTITY_TYPES, UNITS } from 'const'

export const elementRefType = element => {
  if (!element) return oneOfType([func, shape({ current: any })])

  return oneOfType([func, shape({ current: instanceOf(element) })])
}

/**
 * ONLY use this PropType when using useImperativeHandle.
 * This will cover any components or native DOM
 * elements cause they all inherit the base JS object.
 *
 * If you want to type a specific Component or native DOM element,
 * use PropTypes.elementRef to specify the Component or native DOM
 * element the ref is expecting.
 */
export const forwardRefType = oneOfType([func, shape({ current: object })])

export const addressType = shape({
  addressLine1: string,
  addressLine2: string,
  city: string,
  country: string,
  name: string,
  phoneNumber: string,
  state: string,
  uuid: string,
  zipCode: string
})

export const unitType = oneOf(Object.values(UNITS))

export const entityType = shape({
  acreage: number,
  canDelete: bool,
  childrenUUIDs: arrayOf(string),
  country: string,
  county: string,
  extent: arrayOf(number),
  hasLandRights: bool,
  name: string,
  owner: bool,
  parentUUID: string,
  permissions: arrayOf(string),
  rotationId: number,
  state: string,
  type: oneOf(Object.values(ENTITY_TYPES)),
  uuid: oneOfType([number, string]),
  verified: bool
  // we need to add some missing properties
  // I just don't have time to set it up
  // missing properties:
  // poly:
  // centroid:
  // campaignEnrollment:
})
export const productIncentiveTypes = shape({
  id: number,
  isSelected: bool,
  name: string
})

export const lovelandProductsIncentiveTypes = shape({
  incentive: string,
  products: arrayOf(productIncentiveTypes)
})

export const seedType = shape({
  brand: string,
  id: number,
  name: string,
  type: string
})

export const varietyType = shape({
  depthUnit: string,
  depthValue: number,
  populationUnit: string,
  populationValue: number,
  removeSeed: bool,
  rowSpacingUnit: string,
  rowSpacingValue: number,
  seed: seedType,
  seedTreatmentApplied: arrayOf(string),
  seedingMethod: string
})

export const evidenceFileType = shape({
  author: shape({
    email: string,
    firstName: string,
    lastName: string,
    uuid: string
  }),
  eventId: number,
  eventType: string,
  evidenceKey: string,
  evidenceRecord: shape({
    author: shape({
      email: string,
      firstName: string,
      lastName: string,
      uuid: string
    }),
    created: string,
    documentType: string,
    filename: string,
    s3DownloadUrl: string,
    uuid: string
  }),
  evidenceType: oneOf(
    Object.keys(EVIDENCE_TYPE).map(type => EVIDENCE_TYPE[type].evidenceType)
  ),
  uuid: string
})

export const evidenceDataType = oneOfType([
  arrayOf(evidenceFileType),
  objectOf(evidenceFileType)
])

export const evidenceType = shape({
  descriptionKey: string,
  evidenceType: string,
  fsEvent: oneOfType([
    shape({
      CHEMICAL: string,
      FERTILIZER: string,
      HARVEST: string,
      IRRIGATION: string,
      PLANTING: string,
      PLAN_DRAWER: string,
      SAMPLING: string,
      TILLAGE: string
    }),
    arrayOf(string),
    string
  ]),
  instructions: shape({
    descriptionKey: string,
    infoPopover: shape({
      contentKey: string.isRequired,
      labelKey: string.isRequired
    }),
    itemsKeys: arrayOf(string)
  }),
  infoHasIntercomButton: bool,
  isRequestedForNERP: bool,
  requiresGeoTimestamp: bool,
  title: string,
  value: string
})

export const requestedEvidenceType = arrayOf(evidenceType)

export const evidenceRequestedByDOSType = shape({
  isRequired: bool,
  requiresGeoTimestamp: bool,
  evidenceType: string,
  alternativeTo: string
})

export const confirmationModalType = shape({
  eventName: string,
  onClose: func,
  openConfirmModal: bool,
  setOpenConfirmModal: func
})

export const uploadedEventFilesType = shape({
  setUploadedEventFiles: func,
  uploadedEventFiles: evidenceDataType
})

export const carbonPlanType = shape({
  croppingEpisodeId: number,
  plannedReductionAmount: number,
  tillage: string,
  stabilizer: string,
  irrigation: bool,
  nutrientManagementPlanImplemented: bool,
  completed4RNitrogenPlanPrevYear: bool,
  rateDeterminedAtFarmField: string,
  consultedSoilSamplePrevYear: bool,
  includedNutrientCreditPrevCrop: bool,
  varyFertilizerRateLandscapeSoil: bool,
  nBalanceCalculatedPrevYear: bool,
  nitrogenRateAdjustedEefUsed: bool,
  id: number,
  lovelandProductsIncentive: arrayOf({
    incentive: string,
    products: arrayOf({
      id: number,
      name: string,
      isSelected: bool
    })
  })
})
